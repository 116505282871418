import { isNumeric } from 'helpers/miscUtils';
import { strToBoolean } from 'helpers/queryUtils';
import { isBoolean, isString, isNumber } from 'lodash';
import { z } from 'zod';

export const intSchema = z.preprocess((val) => Number(val), z.number().int().nonnegative());

export const numberSchema = z.preprocess(
  (val) => (isNumeric(val) ? Number(val) : val),
  z.number().optional(),
);

export const booleanSchema = z.preprocess(
  (val) => (isBoolean(val) || isString(val) ? strToBoolean(val) : undefined),
  z.boolean().optional(),
);

export const dateSchema = z.preprocess(
  (val) => (isNumber(val) || isString(val) ? new Date(val) : undefined),
  z.date().or(z.null()).optional(),
);
