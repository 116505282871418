import { toQueryStr, queryResponseValidator } from 'helpers/queryUtils';
import { z } from 'zod';
import api from './client';
import { PaginatedRequest, PaginatedResponse } from './types';
import { intSchema } from './shared/common.schema';

export enum TherapyCourseStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum TherapyStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  DONE = 'DONE',
}

export enum PaymentPlan {
  PER_SESSION = 'PER_SESSION',
  PER_COURSE = 'PER_COURSE',
}

const priceIdSchema = z.string().startsWith('price_').max(255);

export const therapyCourseFormSchema = z.object({
  title: z.string().max(255).min(1),
  summary: z.string().min(1),
  description: z.string().optional(),
  image: z.string().max(255).optional(),
  paymentPlan: z.enum([PaymentPlan.PER_SESSION, PaymentPlan.PER_COURSE]),
  priceId: priceIdSchema,
  sessionsAmount: intSchema,
  status: z.enum([TherapyCourseStatus.PUBLISHED, TherapyCourseStatus.DRAFT]),
  assessmentsConfig: z.string().or(z.null()).optional(),
});

export const therapyCourseSchema = z
  .object({
    id: z.string().cuid(),
    isDeleted: z.boolean().optional(),
    price: intSchema.or(z.null()).optional(),
  })
  .merge(therapyCourseFormSchema)
  .extend({
    // we don't allow to submit empty priceId anymore, but such might still arrive from API
    priceId: priceIdSchema.or(z.null()).optional(),
  });

const responseSchema = z.object({ items: z.array(therapyCourseSchema), total: z.number() });

export type TherapyCourseFormValues = z.infer<typeof therapyCourseFormSchema>;
export type TherapyCourse = z.infer<typeof therapyCourseSchema>;

export const getTherapyCourses = async (params: PaginatedRequest<TherapyCourse> = {}) => {
  return api
    .get(`therapyCourses?${toQueryStr(params)}`)
    .json<PaginatedResponse<TherapyCourse>>()
    .then(queryResponseValidator<PaginatedResponse<TherapyCourse>>(responseSchema));
};

export const createTherapyCourse = async (course: TherapyCourseFormValues) => {
  return api.post(`therapyCourses/create`, { json: course }).json();
};

export const updateTherapyCourse = async (id: string, course: Partial<TherapyCourseFormValues>) => {
  return api.patch(`therapyCourses/${id}/update`, { json: course }).json();
};

export const deleteTherapyCourse = async (id: string) => {
  return api.delete(`therapyCourses/${id}/delete`).json();
};
